import React, { useState } from 'react';
import cn from 'classnames';
import s from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { Title } from '../../../ui/Title';
import { useTranslation } from 'react-i18next';
import { ScriptItemProps } from './types';
import {
  BackIcon,
  CopyIcon,
  CopyToOtherIcon,
  DetachedIcon,
  DropDownIcon,
  EditIcon,
  TrashCanIcon
} from '../../../ui/Icons';
import { Notification, YesNoModal } from '../../../ui/Modals';
import { RoleBasedComponent } from '../../../roles';
import { Roles, Status } from '../../../../constants';
import defaultPhoto from '../../../../assets/images/default.jpg';
import { getImage } from '../../../../utils/getImage';
import { Reorder, useDragControls } from 'framer-motion';
import { useCopyScriptToOtherProjectMutation } from '../../../../store/api';
import CopyScriptModal from '../Modals/CopyScriptModal/CopyScriptModal';
import { Link } from 'react-router-dom';

const ScriptItem: React.FC<ScriptItemProps> = ({
  script,
  changeOrders,
  deleteScript,
  copyScript,
  blockScript,
  closeScript,
  isAllScriptsPage
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dragControls = useDragControls();
  const { id, name, price, photos, articul, project_id, internal_id, status } = script;
  const currency = !price?.includes('$') ? ' Грн.' : '';
  const [copyScriptToOtherProject] = useCopyScriptToOtherProjectMutation();
  const [copyToOtherModal, setCopyToOtherModal] = useState(false);
  const [yesNoModal, setYesNoModal] = useState(false);
  const [yesNoModalTitle, setYesNoModalTitle] = useState('');
  const [yesNoModalFunc, setYesNoModalFunc] = useState<(() => void) | null>(null);
  const [notificationModal, setNotificationModal] = useState(false);
  const [notificationText, setNotificationText] = useState('');

  const handleDragControls = (e: React.PointerEvent<HTMLLIElement>) => {
    dragControls.start(e);
  };

  const handleEditScript = () => {
    navigate(`/script/edit/${id}?project_id=${project_id}`);
  };

  const handleCloseCopyToOtherModal = () => {
    setCopyToOtherModal(false);
  };

  const handleOpenYesNoModal = (title: string, func: () => void) => {
    setYesNoModal(true);
    setYesNoModalTitle(title);
    setYesNoModalFunc(func);
  };

  const handleCloseYesNoModal = () => {
    setYesNoModal(false);
    setYesNoModalTitle('');
    setYesNoModalFunc(null);
  };

  const handleCloseNotificationModal = () => {
    setNotificationModal(false);
  };

  const handleCopyModal = () => {
    handleOpenYesNoModal(`${t('areYouShureToCopy')} “${name}”?`, () => functions.handleCopy);
  };

  const handleCopyToOtherModal = () => {
    setCopyToOtherModal(true);
  };

  // Temporarily unavailable
  // const handleDeleteModal = () => {
  //   handleOpenYesNoModal(`${t('areYouShureToDelete')} “${name}”?`, () => functions.handleDelete);
  // };

  const handleCloseModal = () => {
    if (status === Status.ARCHIVED) {
      handleOpenYesNoModal(`${t('areYouShureToUnClose')} “${name}”?`, () => functions.handleClose);
    } else {
      handleOpenYesNoModal(`${t('areYouShureToClose')} “${name}”?`, () => functions.handleClose);
    }
  };

  const handleBlockModal = () => {
    if (status === Status.BLOCKED) {
      handleOpenYesNoModal(`${t('areYouShureToUnBlock')} “${name}”?`, () => functions.handleBlock);
    } else {
      handleOpenYesNoModal(`${t('areYouShureToBlock')} “${name}”?`, () => functions.handleBlock);
    }
  };

  // Functions
  const functions = {
    handleCopy: () => {
      copyScript(id);
      handleCloseYesNoModal();
    },
    handleCopyToOther: (project_id: number) => {
      copyScriptToOtherProject({ id, project_id });
      handleCloseYesNoModal();
      setNotificationText(`${t('scriptsPage.scriptIsCopied')}`);
      setNotificationModal(true);
    },
    handleDelete: () => {
      deleteScript(id);
      handleCloseYesNoModal();
    },
    handleBlock: () => {
      blockScript(id);
      handleCloseYesNoModal();
    },
    handleClose: () => {
      closeScript(id);
      handleCloseYesNoModal();
    }
  };

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = defaultPhoto;
  };

  return (
    <>
      <Reorder.Item
        value={script}
        dragListener={false}
        dragControls={dragControls}
        onDragEnd={changeOrders}
      >
        <Link
          to={`/script/${id}?project_id=${project_id}`}
          className={cn(s.item, { [s.blocked]: status === Status.BLOCKED })}
        >
          <div className={s.wrapper}>
            <img
              src={photos?.[0] ? getImage(photos[0].url) : defaultPhoto}
              onError={handleImageError}
              alt="script-img"
            />
          </div>
          <div className={s.info}>
            <Title title={name} variant="h3" className={s.title} />
            <ul className={s.list}>
              <li className={s.list__item}>
                <span className={s.header}>{t('price')}:</span>{' '}
                <span className={s.value}>{price + currency}</span>
              </li>
              {articul && (
                <li className={s.list__item}>
                  <span className={s.header}>{t('articul')}:</span>{' '}
                  <span className={s.value}>{articul}</span>
                </li>
              )}
              <li className={s.list__item}>
                <span className={s.header}>ID:</span> <span className={s.value}>{internal_id}</span>
              </li>
            </ul>
            <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
              <div
                className={s.functions__wrapper}
                onClick={(e) => e.preventDefault()}
                onMouseDown={(e) => e.preventDefault()}
              >
                <ul className={cn(s.functions, { [s.spec]: isAllScriptsPage })}>
                  {status === Status.ACTIVE && !isAllScriptsPage && (
                    <li className={s.function} onPointerDown={handleDragControls}>
                      <DropDownIcon className={s.dropDownIcon} />
                    </li>
                  )}
                  {status === Status.ACTIVE && (
                    <li className={s.function} onClick={handleEditScript}>
                      <EditIcon className={s.blueIcon} />
                    </li>
                  )}
                  {status !== Status.ARCHIVED && (
                    <li className={s.function} onClick={handleCloseModal}>
                      <TrashCanIcon className={s.redIcon} />
                    </li>
                  )}
                  {status === Status.ARCHIVED && (
                    <li className={s.function} onClick={handleCloseModal}>
                      <BackIcon className={s.blueIcon} />
                    </li>
                  )}
                </ul>
                {status !== Status.ARCHIVED && !isAllScriptsPage && (
                  <ul className={s.functions}>
                    <li className={s.function} onClick={handleCopyModal}>
                      <CopyIcon className={cn(s.blueIcon, s.copyIcon)} />
                    </li>
                    <li className={s.function} onClick={handleCopyToOtherModal}>
                      <CopyToOtherIcon className={cn(s.blueIcon, s.copyIconToOther)} />
                    </li>
                    <li className={s.function} onClick={handleBlockModal}>
                      <DetachedIcon
                        className={cn(s.detachedIcon, { [s.blocked]: status === Status.BLOCKED })}
                      />
                    </li>
                  </ul>
                )}
              </div>
            </RoleBasedComponent>
          </div>
        </Link>
      </Reorder.Item>
      <CopyScriptModal
        existingProjectId={project_id}
        onCopy={functions.handleCopyToOther}
        open={copyToOtherModal}
        onClose={handleCloseCopyToOtherModal}
      />
      <YesNoModal
        title={yesNoModalTitle}
        open={yesNoModal}
        onClose={handleCloseYesNoModal}
        onAgree={yesNoModalFunc ? yesNoModalFunc : () => null}
      />
      <Notification
        title={notificationText}
        open={notificationModal}
        onClose={handleCloseNotificationModal}
      />
    </>
  );
};

export default ScriptItem;
